/* eslint-disable */
<template>
  <div v-if="posts.length >0">
    <h3>
      Список постов
    </h3>
    <transition-group name="post-list">
      <post-items
        v-for="post in posts"
        :post="post"
        :key="post.id"
        @remove="$emit('remove', post)"
    />
    </transition-group>
  </div>
  <h2 v-else style="color:red">Список постов пуст</h2>
</template>

<script>
import PostItems from "@/components/PostItems";
export default {
  name: "PostList",
  components: {PostItems},
  props: {
    posts: {
      type: Array,
      required: true
    }

  }
}
</script>

<style scoped>
.post-list-item {
  display: inline-block;
  margin-right: 10px;
}
.post-list-enter-active,
.post-list-leave-active {
  transition: all 0.4s ease;
}
.post-list-enter-from,
.post-list-leave-to {
  opacity: 0;
  transform: translateX(130px);
}

.post-list-move {
  transition: transform 0.6s ease;
}

</style>