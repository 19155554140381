import axios from "axios";
import {onMounted, ref} from "vue";

export function usePosts(limit) {
    const posts = ref([])
    const page = ref(1)
    const isPostLoading = ref(true)
    const fetching = async () => {
        try {
          const response = await axios.get('https://jsonplaceholder.typicode.com/posts', {
            params:{
              _page: page.value,
              _limit: limit,
            }
        })
      }
      catch (e) {
        alert('Ошибка')
      }
      finally {
        isPostLoading.value = false;
      }
    }
    onMounted(fetching)
    return {
        posts, isPostLoading, page
    }

}