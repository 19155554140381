<template>
<div>
  <h1>
    Добро пожаловать!!!
  </h1>

</div>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>