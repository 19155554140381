<template>
<h1>
  Описание проекта
</h1>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>