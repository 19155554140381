<template>
  <div class="post">
    <div>
      <div>{{ post.id }}</div>
    <div><strong>{{ post.title }}</strong></div>
    <div><strong>{{ post.body }}</strong></div>
    </div>
    <div class="post__btns">
            <my-button
      @click="$router.push(`/posts/${post.id}`)"
            >
        Открыть
      </my-button>
      <my-button
      @click="$emit('remove', post)">
        Удалить
      </my-button>
    </div>

  </div>
</template>

<script>
export default {
  name: "PostItems",
  props: {
    post:{
      type: Object,
      required: true,
    }
  }
}
</script>

<style scoped>

.post {
  padding: 15px;
  border: 2px solid teal;
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.post__btns{
  display: flex;
}

</style>