<template>
<h1>Это страница для поста с ID = {{ $route.params.id }}</h1>
</template>

<script>
export default {
  name: "PostIdPage"
}
</script>

<style scoped>

</style>