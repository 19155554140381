<template>
<div>
  <h1>Страница с постами</h1>
  <my-input
      v-focus
    v-model="searchQuery"
    placeholder="Поиск...."

  />
  <div class="app__btns">
    <my-button
        @click="showDialog"
    >
      Создать пост
    </my-button>
    <my-select
        v-model="selectedSort"
        :options="sortOptions"
    />
  </div>

  <my-dialog v-model:show="dialogVisible">
    <post-form
        @create='createPost'
    />
  </my-dialog>
  <post-list
      :posts="sortedAndSearchedPosts"
      @remove="removePost"
      v-if="!isPostLoading"
  />
  <div v-else>Идёт загрузка</div>
  <div v-intersection="loadMorePosts" class="observer"></div>

</div>
</template>

<script>
import PostForm from "@/components/PostForm";
import PostList from "@/components/PostList";
import MyButton from "@/components/UI/MyButton";

import axios from 'axios';
// import MySelect from "@/components/UI/MySelect";

export default {
  components: {
    MyButton,
    PostList, PostForm
  },
  data() {
    return {
      posts: [],
      dialogVisible: false,
      isPostLoading: false,
      selectedSort: '',
      searchQuery: '',
      page: 1,
      limit: 10,
      totalPages: 0,
      sortOptions: [
        {value: 'title', name: 'По названию'},
        {value: 'body', name: 'По описанию'}
      ]
    }
  },
  methods: {
    createPost(post){
      this.posts.push(post);
      this.dialogVisible = false
    },
    removePost(post){
      this.posts = this.posts.filter(p => p.id !== post.id)
    },
    showDialog () {
      this.dialogVisible = true
    },
    // changePage(pageNumber) {
    //   this.page = pageNumber
    //   // this.fetchPosts()
    // },
    async fetchPosts() {
      try {
        this.isPostLoading = true;
          const response = await axios.get('https://jsonplaceholder.typicode.com/posts', {
            params:{
              _page: this.page,
              _limit: this.limit,
            }
        })
        this.totalPages = Math.ceil(response.headers['x-total-count'] / this.limit);
          this.posts = response.data;
      }
      catch (e) {
        alert('Ошибка')
      }
      finally {
        this.isPostLoading = false;
      }
    },
        async loadMorePosts() {
      try {
        this.page += 1;
          const response = await axios.get('https://jsonplaceholder.typicode.com/posts', {
            params:{
              _page: this.page,
              _limit: this.limit,
            }
        })
        this.totalPages = Math.ceil(response.headers['x-total-count'] / this.limit);
        this.posts = [...this.posts, ...response.data];
      }
      catch (e) {
        alert('Ошибка')
      }
    }
  },
  mounted() {
    this.fetchPosts();

  },

  computed:{
    sortedPosts(){
    return [...this.posts].sort((post1, post2) =>
        post1[this.selectedSort]?.localeCompare(post2[this.selectedSort])
      )
    },
    sortedAndSearchedPosts() {
      return this.sortedPosts.filter( post => post.title.toLowerCase().includes(this.searchQuery.toLowerCase()))
    }
  },

  watch:{
    // page() {
    //   this.fetchPosts()
    // }
  }
  // watch: {
  //   selectedSort(newVal){
  //     this.posts.sort((post1, post2) => {
  //       return post1[newVal]?.localeCompare(post2[newVal])
  //     })
  //   }
  //
  // },

}
</script>

<style>
.app__btns {
  margin: 15px;
  display: flex;
  justify-content: space-between;
}

.page__wrapper{
  display: flex;
  margin-top: 15px;

}

.page{
  border: 1px solid black;
  padding: 10px;

}

.current-page {
  border: 2px solid teal;

}

.observer {
  height: 30px;
}
</style>