<template>
<div>

  <h1>Страница с постами Composition API</h1>
  <my-input
      v-focus
    v-model="searchQuery"
    placeholder="Поиск...."

  />
  <div class="app__btns">
    <my-button
        @click="showDialog"
    >
      Создать пост
    </my-button>
    <my-select
        v-model="selectedSort"
        :options="sortOptions"
    />
  </div>

  <my-dialog v-model:show="dialogVisible">
    <post-form
        @create='createPost'
    />
  </my-dialog>
  <post-list
      :posts="sortedAndSearchedPosts.value"
      @remove="removePost"
      v-if="!isPostLoading"
  />
  <div v-else>Идёт загрузка</div>
  <div v-intersection="loadMorePosts" class="observer"></div>

</div>
</template>

<script>
import PostForm from "@/components/PostForm";
import PostList from "@/components/PostList";
import MyButton from "@/components/UI/MyButton";
import {ref} from 'vue'

import axios from 'axios';
import {usePosts} from "@/hooks/usePosts";
import useSortedPosts from "@/hooks/useSortedPosts";
import useSortedAndSearchedPosts from "@/hooks/useSortedAndSearchedPosts";


export default {
  components: {
    MyButton,
    PostList, PostForm
  },
  data() {
    return {
      dialogVisible: false,
      sortOptions: [
        {value: 'title', name: 'По названию'},
        {value: 'body', name: 'По описанию'}]}
  },
  setup(props){
    const {posts, totalPages, isPostLoading} = usePosts(10)
    const {selectedSort, sortedPosts} = useSortedPosts(posts)
    const {searchQuery, sortedAndSearchedPosts} = useSortedAndSearchedPosts(sortedPosts)

    return {
      posts,
      totalPages,
      isPostLoading,
      selectedSort,
      sortedPosts,
      searchQuery,
      sortedAndSearchedPosts

    }
  }
}
</script>

<style>
.app__btns {
  margin: 15px;
  display: flex;
  justify-content: space-between;
}

.page__wrapper{
  display: flex;
  margin-top: 15px;

}

.page{
  border: 1px solid black;
  padding: 10px;

}

.current-page {
  border: 2px solid teal;

}

.observer {
  height: 30px;
}
</style>